@use '../../styles/mixins' as mixins;

.cta-block-module {
    background: var(--wp--preset--color--bnpp-green-tint-10);
    padding: var(--wp--preset--spacing--60);
    color: var(--wp--preset--color--bnpp-black);
    text-decoration: none;
    position: relative;

    .block-heading {
        display: flex;
        justify-content: space-between;
        gap: var(--wp--preset--spacing--40);

        h2 {
            font-size: var(--wp--preset--font-size--heading-s);
        }

        h3 {
            font-size: var(--wp--preset--font-size--heading-xs);
        }
    }

    p {
        margin-bottom: 0;
    }
    
}